
import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { HasProperyPart } from "@/mixins/has-property-part";
import { FormMoney, FormInput } from "@/components/forms";

@Component({
    components: {
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        FormMoney,
        FormInput,
        ValidationProvider,
    },
})
export default class PropertyPartConstructionRoof extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: PropertyConstructionRoof;

    localValue: PropertyConstructionRoof = {
        material: [],
        material_other: null,
        insulation_available: null,
        insulation_type: null,
        insulation_thickness: null,
    };

    mounted() {}

    toggleArrayProperty(property: string[], value: string) {
        this.toggleArrayPropertyValue(property, value);

        this.handleInput();
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: PropertyConstructionRoof) {
        this.localValue = newValue;
    }
}
